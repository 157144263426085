var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"row mx-0 bg-white mt-2 py-3 br-12"},[_c('div',{staticClass:"col text-center border-right"},[_c('p',{staticClass:"text-general f-30 f-500"},[_vm._v(_vm._s(_vm.separadorNumero(_vm.deudaGeneral.total_comisiones)))]),_c('p',{staticClass:"text-general f-14 mt-1"},[_vm._v("Comisiones sin gestión")])]),_c('div',{staticClass:"col text-center border-right"},[_c('p',{staticClass:"text-general f-30 f-500"},[_vm._v(_vm._s(_vm.deudaGeneral.total_pedidos))]),_c('p',{staticClass:"text-general f-14 mt-1"},[_vm._v("Pedidos")])]),_c('div',{staticClass:"col text-center border-right"},[_c('p',{staticClass:"text-general f-30 f-500"},[_vm._v(_vm._s(_vm.deudaGeneral.total_lecheros))]),_c('p',{staticClass:"text-general f-14 mt-1"},[_vm._v(_vm._s(_vm.$config.vendedor))])]),_c('div',{staticClass:"col text-center border-right"},[_c('p',{staticClass:"text-general f-30 f-500"},[_vm._v(_vm._s(_vm.deudaGeneral.total_cedis))]),_c('p',{staticClass:"text-general f-14 mt-1"},[_vm._v("CEDIS")])])]),_c('div',{staticClass:"row mx-0 mt-3"},[_c('div',{staticClass:"col-12 px-0"},[_c('tabla-general',{attrs:{"data":_vm.dataTable,"columnas":_vm.dataColumns,"alto":"calc(100vh - 428px)","class-header":"text-general f-16"}},[_c('template',{slot:"cabecera-izquierda"},[_c('div',{staticClass:"col-auto text-general f-18 f-500 ml-3"},[_vm._v(" Pedidos pendientes por gestionar la comisión ")])]),_c('template',{slot:"adicionales-derecha"},[_c('el-table-column',{attrs:{"label":"Valor Pedido","align":"center","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general text-center"},[_vm._v(_vm._s(_vm.convertMoney(row.total,row.idm_moneda)))])]}}])}),_c('el-table-column',{attrs:{"label":"Valor Comisión","align":"center","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general text-center"},[_vm._v(_vm._s(_vm.convertMoney(row.valor_condicion,row.idm_moneda)))])]}}])}),_c('el-table-column',{attrs:{"label":"Fecha Pedido","align":"center","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general text-center"},[_vm._v(_vm._s(_vm._f("helper-fecha")(row.entrega_fecha,'DD MMM Y')))])]}}])}),_c('el-table-column',{attrs:{"fixed":"right","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"bg-general3 text-white cr-pointer f-14 br-20 px-2",on:{"click":function($event){return _vm.gestionarComision(row)}}},[_vm._v(" Gestionar ")])])]}}])})],1)],2)],1)]),_c('modal-gestionar-comision',{ref:"modalGestionarComision",on:{"update":_vm.refrestTabla}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }