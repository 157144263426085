<template>
    <section>
        <div class="row mx-0 bg-white mt-2 py-3 br-12">
            <div class="col text-center border-right">
                <p class="text-general f-30 f-500">{{ separadorNumero(deudaGeneral.total_comisiones) }}</p>
                <p class="text-general f-14 mt-1">Comisiones sin gestión</p>
            </div>
            <div class="col text-center border-right">
                <p class="text-general f-30 f-500">{{ deudaGeneral.total_pedidos }}</p>
                <p class="text-general f-14 mt-1">Pedidos</p>
            </div>
            <div class="col text-center border-right">
                <p class="text-general f-30 f-500">{{ deudaGeneral.total_lecheros }}</p>
                <p class="text-general f-14 mt-1">{{ $config.vendedor }}</p>
            </div>
            <div class="col text-center border-right">
                <p class="text-general f-30 f-500">{{ deudaGeneral.total_cedis }}</p>
                <p class="text-general f-14 mt-1">CEDIS</p>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12 px-0">
                <tabla-general :data="dataTable" :columnas="dataColumns" alto="calc(100vh - 428px)" class-header="text-general f-16">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto text-general f-18 f-500 ml-3">
                            Pedidos pendientes por gestionar la comisión
                        </div>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column label="Valor Pedido" align="center" min-width="150">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ convertMoney(row.total,row.idm_moneda) }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Valor Comisión" align="center" min-width="150">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ convertMoney(row.valor_condicion,row.idm_moneda) }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Fecha Pedido" align="center" min-width="150">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ row.entrega_fecha | helper-fecha('DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" width="100">
                            <template slot-scope="{row}">
                                <div class="row mx-0">
                                    <div class="bg-general3 text-white cr-pointer f-14 br-20 px-2" @click="gestionarComision(row)">
                                        Gestionar
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-gestionar-comision ref="modalGestionarComision" @update="refrestTabla" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Liquidacion from '~/services/comisiones/liquidar'
export default {
    components: {
        modalGestionarComision : () => import('./partials/modalGestionarComision')
    },
    data(){
        return {
            dataTable: [],
            dataColumns: [
                { valor: 'id', titulo: 'Pedido', class: 'text-general', ancho:'100'},
                { valor: 'lechero', titulo: 'Lechero', class: 'text-general' },
                { valor: 'cedis_nombre', titulo: 'Cedis', class: 'text-general', ancho:'150' },
            ],
            deudaGeneral:{}
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis'
        }),
    },
    watch:{
        id_cedis(){
            this.getTabla()
            this.getInfoGeneral()
        }
    },
    mounted(){
        if(this.id_cedis != null){
            this.getTabla()
            this.getInfoGeneral()
        }
        this.dataColumns[1].titulo = this.$config.vendedor
    },
    methods: {
        async getTabla(){
            try {

                const {data} = await Liquidacion.listaPendientes()
                this.dataTable = data.pedidos
            } catch (e){
                this.error_catch(e)
            }
        },
        async getInfoGeneral(){
            try {
                const {data} = await Liquidacion.generalPendientes()
                this.deudaGeneral = data
            } catch (e){
                this.error_catch(e)
            }
        },
        refrestTabla(id){
            let index = this.dataTable.findIndex(o=>o.id === id)
            this.dataTable.splice( index, 1 );
            this.getInfoGeneral()
        },
        gestionarComision(row){
            this.$refs.modalGestionarComision.toggle(row);
        }
    }
}
</script>
